<template>
  <Item v-slot="{ textAlign }" :item="item" :parent="parent">
    <div class="w-full">
      <div
        :class="{
          'lg:w-2/3': !isHalf,
          'mr-auto': textAlign === 'text-left',
          'mx-auto': textAlign === 'text-center',
          'ml-auto': textAlign === 'text-right',
        }"
      >
        <Headline
          v-if="headlineItem"
          :item="headlineItem"
          :parent="item"
          :class="introItem ? 'mb-4' : 'mb-8'"
        />

        <Intro
          v-if="introItem"
          :item="introItem"
          :parent="item"
          class="mb-12"
        />

        <EmailForm :item="item.items[0]" :parent="item" class="w-full" />
      </div>
    </div>
  </Item>
</template>

<script>
import itemMixin from '@/mixins/item'
import blockMixin from '@/mixins/block'

export default {
  name: 'EmailFormBlock',

  components: {
    Item: () => import('@/components/Item'),
    Headline: () => import('@/components/Items/Headline'),
    Intro: () => import('@/components/Items/Intro'),
    EmailForm: () => import('@/components/Items/EmailForm'),
  },

  mixins: [blockMixin, itemMixin],

  computed: {
    headlineItem() {
      return this.item.items.find(item => item.name === 'Headline')
    },

    introItem() {
      return this.item.items.find(item => item.name === 'BodyText')
    },
  },
}
</script>

<style></style>
